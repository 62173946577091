import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './css/ThankYouPage.css'; // This is for adding the styles separately




const ThankYouPage = () => {
    const navigate = useNavigate();
    useEffect(() => {
        // Set a timeout to redirect after 5 seconds
        const timer = setTimeout(() => {
          window.location.href = 'https://academically.com'; // Redirect to the external URL
        }, 15000); // 5000ms = 5 seconds
    
        // Cleanup the timer if the component unmounts before the timeout completes
        return () => clearTimeout(timer);
      }, [navigate]);

  return (
    <div className="thank-you-container">
      <div className="thank-you-box">
        <h1>Thank You!</h1>
        
        <p style="color:gray"><strong>We appreciate your participation and support!</strong></p>
      </div>
    </div>
  );
};

export default ThankYouPage;
